const Parse = global.Parse;

const load = () => ({ type: 'LOAD_ACCOUNT', payload: Parse.Cloud.run('load') });

const clear = () => ({ type: 'CLEAR_ACCOUNT', payload: Promise.resolve(null) });

const subscribe = () => ({
    type: 'SUBSCRIBE',
    payload: Parse.Cloud.run('subscribe'),
});

const updateSubscription = (sessionId) => ({
    type: 'UPDATE_SUBSCRIPTION',
    payload: Parse.Cloud.run('update-subscription', { sessionId })
});

export default { load, clear, subscribe, updateSubscription };
