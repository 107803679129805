import { ActivityPicture } from 'domain';

const Parse = global.Parse;

const ACTIVITY_PICTURE_FIELDS = Object.keys(ActivityPicture.DEFAULTS);

const get = (id) => ({
    type: 'GET_ACTIVITY_PICTURE',
    meta: { id },
    payload: new Parse.Query(ActivityPicture)
        .select(ACTIVITY_PICTURE_FIELDS)
        .get(id),
});

const listByActivity = (activity) => ({
    type: 'LIST_ACTIVITY_PICTURE',
    meta: { activity },
    payload: new Parse.Query(ActivityPicture)
        .equalTo('activity', activity)
        .select(ACTIVITY_PICTURE_FIELDS)
        .find(),
});

const set = (activityPicture) => ({
    type: 'SET_ACTIVITY_PICTURE',
    meta: { activityPicture },
    payload: Promise.resolve(activityPicture),
});

const save = (activityPicture) => ({
    type: 'SAVE_ACTIVITY_PICTURE',
    meta: { activityPicture },
    payload: activityPicture.save(),
});

const clear = () => ({
    type: 'CLEAR_ACTIVITY_PICTURE',
    meta: {},
    payload: Promise.resolve(null),
});

export default {
    get,
    listByActivity,
    set,
    save,
    clear,
};
