import WatcherUtils from "utils/WatcherUtils";
import {Activity, ActivityCriteria} from 'domain';

const Parse = global.Parse;

const ACTIVITY_FIELDS = Object.keys(Activity.DEFAULTS)

const get = (id) => ({
    type: 'GET_ACTIVITY',
    meta: { id },
    payload: new Parse.Query(Activity)
        .select(ACTIVITY_FIELDS)
        .get(id),
});


const _generateWhereAndWhenQuery = (criteria, limit = 100, exclude = []) => {
    const { startDate, endDate, locations, employees, sortDir, sortField } = criteria;

    if (!startDate || !(startDate instanceof Date)) {
        throw new Error("Invalid start date provided.");
    }
    if (endDate && !(endDate instanceof Date)) {
        throw new Error("Invalid end date provided.");
    }

    let query = new Parse.Query(Activity)
        .greaterThanOrEqualTo('requestedAt', startDate)
        .notEqualTo('status', Activity.STATUS_ARCHIVED)
        .select(ACTIVITY_FIELDS)
        .limit(limit);

    if (sortDir === ActivityCriteria.SORT_DIR_ASC) {
        query = query.ascending([sortField, 'requestedAt'])
    } else {
        query = query.descending([sortField, 'requestedAt'])
    }

    if (Array.isArray(locations) && locations.length > 0) {
        query = query.containedIn('location', locations);
    }

    if (Array.isArray(employees) && employees.length > 0) {
        query = query.containedIn('employee', employees);
    }

    if (endDate) {
        query = query.lessThanOrEqualTo('requestedAt', endDate);
    }

    if (exclude.length > 0) {
        query = query.notContainedIn("objectId", exclude);
    }

    return query;
};

/**
 * Gets more activities based on a date range and an array of locations. These activities will be appended to the
 * existing list of activities.
 *
 * @param {Date} start - The start date for filtering activities.
 * @param {Date} [end] - The optional end date for filtering activities.
 * @param {Location[]} locations - An array of Location objects.
 * @returns An action object for getting activities.
 */
const listByWhereAndWhen = (criteria, limit = 100, exclude = [], isAppend = false) => ({
    type: isAppend ? 'APPEND_ACTIVITY' : 'LIST_ACTIVITY',
    meta: { criteria, exclude, limit, isAppend },
    payload: _generateWhereAndWhenQuery(criteria, limit, exclude).find(),
});

const watchByWhereAndWhen = (criteria, functions) => ({
    type: 'WATCH_ACTIVITY',
    meta: { criteria, functions },
    payload: WatcherUtils.watch(
        'activity',
        'byWhenAndWhere',
        functions,
        _generateWhereAndWhenQuery(criteria)
    ),
});

const unwatchByWhereAndWhen = () => {
    return {
        type: 'UNWATCH_ACTIVITY_BY_DEPLOYMENT_LOCATIONS',
        meta: {},
        payload: WatcherUtils.unwatch('activity', 'byWhenAndWhere'),
    };
};

const listAll = () => ({
    type: 'LIST_ACTIVITY',
    meta: {},
    payload: new Parse.Query(Activity)
        .select(ACTIVITY_FIELDS)
        .notEqualTo('status', Activity.STATUS_ARCHIVED)
        .descending(['checkin', 'requestedAt'])
        .find(),
});

const listMostRecent = (limit = 10) => ({
    type: 'LIST_ACTIVITY',
    meta: {},
    payload: new Parse.Query(Activity)
        .select(ACTIVITY_FIELDS)
        .notEqualTo('status', Activity.STATUS_ARCHIVED)
        .limit(limit)
        .descending('updatedAt')
        .find(),
});

const listByEmployee = (employee, statuses) => {
    let query = new Parse.Query(Activity)
        .equalTo('employee', employee)
        .notEqualTo('status', Activity.STATUS_ARCHIVED)
        .select(ACTIVITY_FIELDS)
        .descending('requestedAt');

    if (Array.isArray(statuses) && statuses.length > 0) {
        query.containedIn('status', statuses);
    }

    return {
        type: 'LIST_ACTIVITY',
        meta: { employee, statuses },
        payload: query.find(),
    };
};


const watchByEmployee = (employee, statuses, functions) => {
    let query = new Parse.Query(Activity)
        .equalTo('employee', employee)
        .select(ACTIVITY_FIELDS);

    if (Array.isArray(statuses) && statuses.length > 0) {
        query.containedIn('status', statuses);
    }

    return {
        type: 'WATCH_ACTIVITY',
        meta: { employee, statuses },
        payload: WatcherUtils.watch(
            'activity',
            employee.id,
            functions,
            query
        ),
    };
};

const unwatchByEmployee = (employee) => {
    return {
        type: 'UNWATCH_ACTIVITY',
        meta: { employee },
        payload: WatcherUtils.unwatch('activity', employee.id),
    };
};

const set = (activity) => {
    return {
        type: 'SET_ACTIVITY',
        meta: { activity },
        payload: Promise.resolve(activity),
    };
};

const setAll = (activities) => {
    return {
        type: 'SET_ALL_ACTIVITY',
        meta: { activities },
        payload: Promise.resolve(activities),
    };
};

const addAll = (activities) => {
    return {
        type: 'ADD_ALL_ACTIVITY',
        meta: { activities },
        payload: Parse.Object.saveAll(activities),
    };
};

const saveAll = (activities) => ({
    type: 'SAVE_ALL_ACTIVITY',
    meta: { activities },
    payload: Parse.Object.saveAll(activities),
});

const save = (activity) => ({
    type: 'SAVE_ACTIVITY',
    meta: { activity },
    payload: activity.save(),
});

/**
 * Signals to reducer to clear the activity data.
 */
const clear = () => ({
    type: 'CLEAR_ACTIVITY',
    meta: {},
    payload: Promise.resolve(null)
});

export default {
    get,
    set,
    save,
    listAll,
    listMostRecent,
    listByEmployee,
    listByWhereAndWhen,
    watchByWhereAndWhen,
    unwatchByWhereAndWhen,
    watchByEmployee,
    unwatchByEmployee,
    saveAll,
    addAll,
    setAll,
    clear,
};
